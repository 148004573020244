/*

  File: content/fragment.jsx
  Kind: Component
  Description: Map a Fragment onto MDXRenderer with image props

*/

import * as React from 'react';

//Components
import { MDXRenderer } from 'gatsby-plugin-mdx';


const Fragment = ({data}) => {

  // console.log(data);

  if (data) {
    return (
      <MDXRenderer images={data.images}>{data.body}</MDXRenderer>
    )
  } else {
    return ''
  }

}

export default Fragment
