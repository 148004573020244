/*

  File: index.js
  Kind: Page
  Description: Page banner with title and subtitle that are read from the site config

*/

import * as React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout.js';
import Fragment from '../components/content/fragment.jsx';

//functions
import PrepareFragments from '../components/content/prepareFragments.js';

const Colophon = ({ data, location }) => {
  const Fragments = PrepareFragments(data.fragments);

  // console.log('ARTIST_PIC:', Fragments);

  return (
    <Layout pageTitle="colophon" location={location}>
      <div className="flex flex-row space-x-8">
        <div className="w-1/3">
          <Fragment data={Fragments.artistPicture} />
        </div>
        <div className="flex flex-col mt-6">
          <div className="">
            <Fragment data={Fragments.intro} />
          </div>
          <div className="">
            <Fragment data={Fragments.madeWith} />
          </div>
          <div className="">
            <Fragment data={Fragments.hostedBy} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    fragments: allMdx(filter: { fields: { collection: { eq: "fragment" }, locator: { page: { eq: "colophon" } } } }) {
      nodes {
        ...MarkdownFragments
      }
    }
  }
`;

export default Colophon;
